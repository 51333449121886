import React from "react";
import InformationCard from "./InformationCard";
import '../Styles/AddedInfo.css'; // Your CSS file
import { faDollar, faSackDollar, faPeopleArrows, faPersonMilitaryToPerson, faBalanceScale, faTree } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";
import management from "../Assets/management.png";
import finance from "../Assets/finance.png";
import internal from "../Assets/internal.png";
import social from "../Assets/social.png";
import statutory from "../Assets/Statutory.png";
import tax from "../Assets/tax.png";

function Info() {
  
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Offer</span>
        </h3>
        <p className="info-description">
        At Sedai BK Associates, we provide a wide range of audit and assurance services 
        designed to enhance transparency and compliance in your financial operations. Our 
        offerings include:
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="MANAGEMENT AUDIT"
          description="A systematic and comprehensive evaluation of an organization's management practices, processes, and systems.  It is a management audit is a vital tool for improving an organization's management practices and overall performance. By systematically evaluating management processes and identifying areas for improvement, organizations can enhance their efficiency, effectiveness, and ability to achieve their strategic objectives."
          icon={faTree}
          bg={management}
        />

        <InformationCard
          title="FINANCE AUDIT"
          description="A finance audit is essential for ensuring the accuracy, compliance with accounting standards and reliability of an organization’s financial statements. By systematically evaluating financial processes and identifying areas for improvement, organizations can enhance their financial reporting, ensure compliance, and improve overall financial health. The primary goal is to provide assurance that the financial statements are free of material misstatement and present a true and fair view of the financial position of the organization."
          icon={faSackDollar}
          bg={finance}
        />

        <InformationCard
          title="TAX AUDIT"
          description="A tax audit is essential for ensuring compliance with tax laws and accuracy in tax reporting. By systematically evaluating tax-related processes and identifying areas for improvement, organizations and individuals can enhance their tax compliance, minimize risks, and avoid potential penalties. The objective is to verify that the correct amount of tax has been paid. It ensures an organization’s or individual’s tax returns by the tax authorities as per reported financial information and other related data in accordance with tax laws."
          icon={faDollar}
          bg={tax}
        />

        <InformationCard
          title="INTERNAL AUDIT"
          description="An internal audit is essential for maintaining and improving an organization’s risk management, control, and governance processes. By systematically evaluating these areas and identifying opportunities for improvement, internal auditors provide valuable insights that help organizations achieve their objectives, ensure compliance, and enhance overall efficiency and effectiveness. It helps an organization accomplish its objectives by bringing a systematic, disciplined approach to evaluate and improve the effectiveness of risk management, control, and governance processes."
          icon={faPersonMilitaryToPerson}
          bg={internal}
        />
        
        <InformationCard
          title="SOCIAL AUDIT"
          description="A social audit is essential for assessing and improving an organization’s social responsibility performance. By systematically evaluating social, ethical, and environmental aspects of operations, organizations can enhance their accountability, transparency, and positive impact on society. This process not only helps in meeting regulatory requirements but also strengthens stakeholder trust and supports long-term sustainability goals. Unlike traditional audits that focus primarily on financial performance, social audits assess the social impact of an organization’s activities and its adherence to social responsibility principles. This type of audit is often used by non-profits, corporations, and public sector organizations to demonstrate accountability and transparency to stakeholders."
          icon={faPeopleArrows}
          bg={social}
        />

        <InformationCard
          title="STATUTORY AUDIT"
          description="A statutory audit is essential for ensuring the accuracy and reliability of an organization's financial statements and for maintaining compliance with legal and regulatory requirements. By systematically evaluating financial processes and controls, statutory auditors provide valuable assurance to stakeholders and help enhance the organization's financial integrity and transparency. The purpose of a statutory audit is to determine whether an organization is providing a fair and accurate representation of its financial position by examining information such as bank balances, bookkeeping records, and financial transactions. This type of audit is mandated by law and typically must adhere to specific guidelines and standards set forth by regulatory bodies."
          icon={faBalanceScale}
          bg={statutory}
        />

      </div>
    </div>
  );
}

export default Info;