import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";

function InformationCard(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  return (
    <div
      className="info-cards"
      onMouseEnter={toggleDetails}
      onMouseLeave={toggleDetails}
    >
      <div
        className="container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="info-card-icon">
          <FontAwesomeIcon className="info-fa-icon" icon={props.icon} />
        </span>
        <p className="info-card-title">{props.title}</p>
        <CSSTransition
          in={showDetails}
          timeout={100} // Adjust as needed
          classNames="ease" // CSS class for transition
          unmountOnExit
        >
          <div
            className="details"
            style={{
              backgroundImage: isHovered ? `url(${props.bg})` : "none",
              alt: "Placeholder",
              backgroundSize: "contain",
              /* Add other styles as needed */
            }}
          >
            <div className="info-card-description">
              <p>{props.description}</p>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default InformationCard;
